.wrapper {
  position: relative;
  z-index: 1;
  padding: 80px 20px;
  background-color: var(--color-grey-200);

  &_inner {
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
  }
}