@import '../../../styles/helpers/responsive.scss';

.section_wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 30px;

  @include mobile {
    margin-top: 50px;
    margin-bottom: 10px;
  }
}

.section {
  position: relative;
  min-height: 643px;

  @include mobile {
    min-height: initial;
  }
}

.list {
  position: relative;
  margin: 40px 0;

  @include mobile {
    margin: 40px 0 60px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 30px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: var(--color-secondary-100);

    @include mobile {
      left: 40px;
    }
  }
}

.list_item {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;

  @include mobile {
    background-color: var(--color-white);
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 30px 80px 20px 20px;
  }

  &:first-child {
    padding-top: 0;

    @include mobile {
      padding-top: 30px;
    }
  }

  &:last-child {
    padding-bottom: 0;

    @include mobile {
      padding-bottom: 20px;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-secondary);
    font-size: var(--fs-24);
    font-weight: 700;
    min-width: 60px;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    background-color: var(--color-white);
    box-shadow: 0 4px 20px rgba(31, 51, 116, 0.07);
    border-radius: 5px;

    @include mobile {
      position: absolute;
      top: -20px;
      left: 20px;
      min-width: 40px;
      width: 40px;
      height: 40px;
      font-size: var(--fs-20);
    }
  }
}

.reinsurance {
  position: relative;
  text-align: center;
  padding: 20px 0;

  &::after, &::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 70%;
    background: linear-gradient(94.66deg, var(--color-primary) 0.98%, rgba(255, 255, 255, 0.17) 102.56%);
  }

  &::after {
    bottom: 0;
    right: 0;
    left: initial;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &::before {
    top: 0;
    left: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.mobileIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  min-width: 60px;
  border-radius: 0 0 10px 0;
}

.section_container, .images {
  padding: 60px 0;

  img {
    background-color: var(--color-grey-200);
  }
}

.section_container {
  max-width: 50%;
  margin-top: 20px;
  padding: 60px 60px 20px 0;

  @include mobile {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
}

.how_paragraph {
  max-width: 500px;
}

.images {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: auto;
  width: 50%;
  margin-top: 60px;

  @include mobile {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 520px;
    height: 100%;
    width: 100%;
    background-color: var(--color-grey-200);

    @include laptop {
      left: 440px;
    }

    @include tablet {
      left: 80px;
    }

    @include mobile {
      display: none;
    }
  }

  img {
    border-radius: 5px;
  
    &.img2 {
      border-radius: 5px;

      @include laptop {
        border-radius: 5px 0 0 5px;
      }
    }
  }
}

.image {
  border: 2px solid red;
  & > span {
    height: 100%;
  }
}