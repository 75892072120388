.container {
  position: relative;
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  // padding-bottom: 71.60%;
  background-color: var(--color-grey-400);
  border-radius: 10px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%) skew(-20deg);
    background-image:
      linear-gradient(
        90deg,
        var(--color-loader-100-0) 0,
        var(--color-loader-100-20) 20%,
        var(--color-loader-100-50) 60%,
        var(--color-loader-100-0)
      );
    animation: shimmer 1s infinite;
  }

  iframe {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 3;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  }

  .hover_label {
    position: absolute;
    z-index: 3;
    top: calc(50% + 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    text-align: center;
    line-height: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  }

  &.not_playing {
    &:hover {
      &::before {
        opacity: 0.6;
        visibility: visible;
      }
  
      .hover_label {
        opacity: 1;
        visibility: visible;
      }
    }
  }

}

@keyframes shimmer {
  100% {
    transform: translateX(100%) skew(-20deg);
  }
}

.thumbnail {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  visibility: visible;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  cursor: pointer;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.play_icon {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  pointer-events: none;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
  border-radius: 50%;

  // blur
  background-color: hsla(0, 0%, 97%, .35);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}
