@mixin no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
}

@mixin iphone-toolbar {
  @supports(padding: max(0px)) {
    padding-bottom: max(20px, env(safe-area-inset-bottom));
  }
}
