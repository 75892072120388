@import '/styles/helpers/responsive.scss';

.flexible {
  @include mobile {
    background-color: var(--color-bg);
    padding: 20px;
  }
}

.slider {
  max-width: 100%;
  overflow: auto;
  padding-bottom: 15px;

  @include mobile {
    overflow: hidden;
    flex-wrap: wrap;
  }

  & > div {
    & > label > span {
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}