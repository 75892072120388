@import '/styles/helpers/responsive.scss';

.card_wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  margin-right: 10px;
  min-height: 76px;
  white-space: nowrap;
  cursor: pointer;

  @include mobile {
    min-height: 50px;
    max-height: initial;
    width: 100%;
    margin: 0 0 10px;
    white-space: normal;
  }

  &:last-child {
    margin-right: 0;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;

    &:checked {
      & + .card_box {
        background-color: var(--color-secondary-100);
        border-color: var(--color-secondary-300);

        @include mobile {
          opacity: 1;
          border-color: var(--color-secondary);
          background-color: transparent;
        }

        &::before {
          background-color: var(--color-secondary);

          @include mobile {
            background-color: transparent;
          }
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .card {
    min-width: 220px;
    min-height: 100%;
    padding: 10px 40px;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-400);
    border-radius: 5px;
    font-size: var(--fs-14);
    font-weight: 500;
    line-height: 1.2;
    cursor: pointer;
    transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out, opacity .3s ease-in-out;

    &.auto {
      min-width: 135px;

      @include mobile {
        min-width: 100%;
      }
    }

    @include mobile {
      min-width: 100%;
      max-width: 100%;
      padding: 10px 60px 10px 20px;
      font-weight: 400;
    }

    &.selected {
      background-color: var(--color-secondary-100);
      color: var(--color-secondary);
      border-color: var(--color-secondary);
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    .card_box {
      position: absolute;
      z-index: 1;
      top: 5px;
      right: 5px;
      width: 18px;
      min-width: 18px;
      height: 18px;
      border: 1px solid var(--color-grey-300);
      border-radius: 3px;
      transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;

      @include mobile {
        border: 1px solid var(--color-secondary);
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        opacity: 0;
        border-radius: 50%;
      }

      &.is_radio {
        border-radius: 50%;

        &::before {
          border-radius: 50%;
        }

        &::after {
          display: none;

          @include mobile {
            display: block;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        display: block;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-grey-300);
        border-radius: 2px;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        @include mobile {
          background-color: transparent;
        }
      }

      &::after {
        content: url('data:image/svg+xml; utf8, <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 3.5L2.92857 5.5L7.5 0.5" stroke="%23F9ECEB"/></svg>');
        position: absolute;
        display: block;
        z-index: 1;
        top: calc(50% - 2px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transform: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out, color .3s ease-in-out;

        @include mobile {
          content: url('data:image/svg+xml; utf8, <svg width="10" height="9" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 3.5L2.92857 5.5L7.5 0.5" stroke="%23F46E54"/></svg>');
          opacity: 1;
          top: 50%;
        }
      }
    }
  }
}