@import '/styles/helpers/responsive.scss';

.players {
  padding: 0 18px;

  @include mobile {
    padding: 0 20px;
  }
}

.player {
  padding: 18px 0 20px;
  border-bottom: 1px solid var(--color-grey-200);
  font-size: var(--fs-14);
  font-weight: 500;

  &:last-child {
    border-bottom: none;
  }
}

.warning {
  width: 100%;
  font-weight: 400;
  padding-top: 10px;
  font-size: var(--fs-12);
  line-height: 1.6;
  color: var(--color-black-600);

  @include mobile {
    padding: 10px 20px;
  }
}
