@import '../../../styles/helpers/responsive.scss';

.themes_wrapper {
  position: relative;
  z-index: 1;
  background-color: var(--color-primary-700);
  color: var(--color-white);
  padding: 80px 0;
  margin-top: 80px;

  @include mobile {
    background-color: transparent;
    padding: 30px 0 60px;
    color: var(--color-black);
    margin-top: 40px;
  }
}

.mainTitle {
  text-align: center;
  font-size: var(--fs-34);

  @include tablet {
    font-size: var(--fs-30);
  }
  
  @include mobile {
    text-align: left;
    color: var(--color-primary);
    line-height: 1.2;
    font-size: var(--fs-20);
  }
}

.subtitle {
  &::after {
    @include mobile {
      background-color: var(--color-secondary);
    }
  }
}