@import '../../../styles/helpers/responsive.scss';

.wrapper {
  position: relative;
  z-index: 1;
  padding: 80px 0;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    height: 300px;
    width: 30vw;
    background-color: var(--color-white);

    @include mobile {
      display: none;
    }
  }
}

.slider {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
}

.embla {
  overflow: hidden;
}

.embla_slide {
  position: relative;
  flex: 0 0 100%;
  min-width: 0;
  margin: 0;
  padding: 0 20px;

  .inner_slide {
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    padding: 55px 70px 55px;
    border-radius: 10px;
    background-color: var(--color-primary-700);
    color: var(--color-white);
    cursor: grab;

    @include tablet {
      padding: 40px 20px;
    }
  }

  .slide_media {
    width: 50%;

    @include tablet {
      width: 100%;
    }
  }

  .slide_content {
    width: 50%;
    padding-right: 50px;

    @include tablet {
      width: 100%;
      padding-right: 0;
    }
  }
}

.nav_wrapper {
  cursor: default;

  @include tablet {
    margin-top: auto;
  }
}

.nav {
  cursor: pointer;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    transform: scale(1);
  }
}
