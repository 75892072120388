@import '/styles/variables.scss';
@import '/styles/helpers/responsive.scss';
@import '/styles/helpers/mixins.scss';

.bar_wrapper {
  position: sticky;
  z-index: 3;
  top: -1px;
  left: 0;
  width: 100%;
  padding-top: calc(#{$header-height} + 1px);
  margin-top: calc(-#{$header-height} - 50px);

  @include tablet {
    position: relative;
    top: 0;
    padding: 0 20px;
    margin: -200px auto 0;
    max-width: 800px;
  }

  @include mobile {
    max-width: initial;
  }
}

.bar {
  position: relative;
  background-color: var(--color-white);
  box-shadow: 0px 4px 20px rgba(31, 51, 116, 0.07);
  border-radius: 10px;
  margin: 0 auto;
  max-width: 100%;
  flex: 0 0 auto;
  transition: flex .4s ease-in-out, border-radius .4s ease-in-out, padding .4s ease-in-out;

  @include tablet {
    width: 100%;
    box-shadow: none;
  }
}

.title {
  position: absolute;
  top: -50px;
  left: 0;
  color: var(--color-white);
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
}

@include all-but-tablet {
  .is_sticky {
    padding-right: 0;
    padding-left: 0;

    .bar {
      flex: 1 1 auto;
      border-radius: 0;
    }
  
    .title {
      opacity: 0;
      visibility: hidden;
    }
  
    .sticky_max {
      max-width: 1380px;
      margin: 0 auto;
      padding: 0 60px;

      @include tablet {
        padding: 0;
      }
    }
  
    .submit {
      button {
        padding: 10px 12px 11px;
        font-size: var(--fs-14);
      }
    }
  
    .input_container {
      min-height: 70px;

      & > div > button {
        &:first-of-type {
          border-radius: 0;
        }
      }
    }
  }
}

.submit {
  @include tablet {
    width: 100%;
    margin: 30px 0 15px;
  }

  & > button {
    white-space: nowrap;

    @include tablet {
      width: 100%;
      padding: 17px 30px 19px;
    }
  }
}

.sticky_max {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  transition: padding .4s ease-in-out, max-width .4s ease-in-out;

  @include tablet {
    padding: 20px;
  }
}

.r_sep {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 40px;
    width: 1px;
    background-color: var(--color-grey-300);
    
    @include tablet {
      display: none;
    }
  }
}

.input_container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100px;
  transition: min-height .4s ease-in-out;

  @include tablet {
    min-height: 50px;
    border: 1px solid var(--color-grey-400);
    border-radius: 5px;
    width: 100%;
    margin: 8px 0;

    & > * {
      width: 100%;
    }
  }

  & > div:first-child > button {
    @include tablet {
      position: relative;
      border-radius: 5px;
      width: 100%;
      padding-right: 50px;

      &::after {
        content: url('data:image/svg+xml; utf8, <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1.5L6 6.5L1 1.5" stroke="currentColor" stroke-width="0.75" stroke-linecap="square" stroke-linejoin="round"/></svg>');
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &:first-of-type {
    & > div > button {
      border-radius: 10px 0 0 10px;

      @include tablet {
        border-radius: 5px;
      }
    }
  }
}

.sticky_mobile_search {
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  padding: 20px 20px 30px;
  background-color: var(--color-white);

  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out, bottom .2s ease-in-out;

  @include iphone-toolbar;

  &.show {
    opacity: 1;
    visibility: visible;
    bottom: 0;
  }

  button {
    padding: 17px 20px;
    font-weight: 400;
  }
}