@import '/styles/helpers/mixins.scss';

.mobile_reset {
  position: sticky;
  padding-top: 15px;
  bottom: 85px;
  background-color: var(--color-white);

  @include iphone-toolbar;
}
