@import '../../../styles/helpers/responsive.scss';

.slider {
  position: relative;
  overflow: hidden;
}

.embla {
  width: 100%;
  height: 100%;
}

.embla_container {
  position: relative;
  display: flex;
  cursor: grab;

  @include mobile {
    height: 100%;
  }
}

.slide {
  position: relative;
  height: 100%;
  transition: transform .3s ease-in-out;

  img {
    background-color: var(--color-grey-200);
  }
}

.embla_slide {
  position: relative;
  min-width: 100%;
  height: 659px;

  @include mobile {
    height: 100%;
  }
}

.dots {
  position: absolute;
  z-index: 1;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    bottom: 80px;
  }
}

.dot {
  display: block;
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 0 4px;
  background-color: var(--color-white);
  opacity: 0.5;
  border-radius: 50%;
  transition: opacity .3s ease-in-out, width .3s ease-in-out, height .3s ease-in-out, min-width .3s ease-in-out;
}

.active_dot {
  opacity: 1;
  width: 16px;
  min-width: 16px;
  height: 16px;
}