@import '../../../styles/helpers/responsive.scss';

.embla {
  overflow: hidden;

  @include mobile {
    margin: 0 -20px;
    padding: 0 10px;
  }
}

.embla_container {
  display: flex;
  margin: 0 -10px;

  @include mobile {
    margin: 0 -5px 0 0;
  }
}

.slider {
  margin: 20px 0 0;

  @include mobile {
    margin: 25px 0 0;
  }
}

.slide {
  display: block;
  position: relative;
  height: 100%;
  width: calc(100% - 26px);
  margin: 0 13px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform .3s ease-in-out;
  cursor: pointer;

  img {
    background-color: var(--color-primary);
  }

  @include desktop-only {
    &:hover {
      transform: translateY(5px);
    }   
  }

  &:hover {
    .search_arrow {
      opacity: 1;
      visibility: visible;
    }
  }
}

.embla_slide {
  position: relative;
  min-width: calc(100% / 4);
  height: 394px;

  @include tablet {
    min-width: calc(100% / 3);
  }

  @include mobile {
    min-width: calc(100% - 40px);
    height: 310px;
  }
}

.slide_title {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(#191a3c, .7);
  color: var(--color-white);
  padding: 20px;
  font-size: var(--fs-18);
  font-weight: 500;
  text-align: left;

  @include mobile {
    font-size: var(--fs-18);
    border-radius: 0 0 10px 10px;
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .slide_title {
    background: linear-gradient(94.66deg, rgba(162, 176, 188, 0.6) 0.98%, rgba(162, 176, 188, 0.08) 102.56%);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
}

.search_arrow {
  min-width: 35px;
  margin-left: 5px;

  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.nav {
  position: relative;

  @include mobile {
    display: none;
  }
}

.navButton {
  cursor: pointer;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    transform: scale(1);
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.navIcon {
  width: 40px;
  min-width: 40px;
  margin: 0 5px;
}

.more {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}
