@import '../../../styles/helpers/responsive.scss';

.event_wrapper {
  position: relative;
  z-index: 1;
  margin: 0 0 20px;
  padding-top: 80px;

  @include mobile {
    padding: 0 0 30px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: calc(50% - 400px);
    height: 300px;
    background-color: var(--color-white);

    @include mobile {
      display: none;
    }
  }
}

.event_wrapper_odd {
  &::before {
    left: initial;
    right: 0;
  }
}

.img_container {
  position: relative;
  min-width: 630px;
  width: 630px;
  height: 629px;
  box-shadow: 0 4px 20px rgb(31,51,116, 0.1);
  border-radius: 10px;
  overflow: hidden;
  
  @include tablet {
    min-width: 400px;
  }

  @include mobile {
    min-width: initial;
    width: 100%;
    max-height: 220px;
  }

  img {
    background-color: var(--color-grey-200);
  }
}

.img_legend {
  position: absolute;
  bottom: 40px;
  left: 40px;
  padding: 17px 20px 20px;
  border-radius: 5px;
  color: var(--color-white);
  font-weight: 400;
  background-color: rgba(#191a3c, .7);
  width: calc(100% - 130px);
  max-width: 390px;

  @include mobile {
    width: 100%;
    max-width: initial;
    left: 0;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.img_legend_odd {
  left: initial;
  right: 40px;

  @include mobile {
    right: 0;
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .img_legend {
    background: linear-gradient(94.66deg, rgba(162, 176, 188, 0.6) 0.98%, rgba(162, 176, 188, 0.08) 102.56%);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }
}

.event_block {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(31, 51, 116, 0.1);
  padding: 35px 40px 40px;
  max-width: 630px;
  min-width: calc(100% - 690px);
  transform: translateX(-80px);
  margin-right: -80px;

  @include tablet {
    min-width: initial;
    transform: translateX(-40px);
    margin-right: -40px;
  }

  @include mobile {
    transform: translateX(0);
    margin-right: 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    max-width: initial;
  }
}

.event_block_odd {
  transform: translateX(80px);
  margin-right: 0;
  margin-left: -80px;

  @include tablet {
    transform: translateX(40px);
    margin-left: -40px;
  }

  @include mobile {
    transform: translateX(0);
    margin-left: 0;
  }
}
