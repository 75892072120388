// RESPONSIVE
$mobile-xs: 320px;
$mobile-s: 375px;
$mobile: 768px;
$tablet: 1024px;
$laptop-s: 1200px;
$laptop: 1440px;
$desktop: 1920px;

@mixin mobile-xs {
  @media all and (max-width: $mobile-xs) { @content; }
}

@mixin mobile-s {
  @media all and (max-width: $mobile-s) { @content; }
}

@mixin mobile {
  @media all and (max-width: $mobile) { @content; }
}

@mixin tablet {
  @media all and (max-width: $tablet) { @content; }
}

@mixin laptop-s {
  @media all and (max-width: $laptop-s) { @content; }
}

@mixin laptop {
  @media all and (max-width: $laptop) { @content; }
}

@mixin desktop {
  @media all and (max-width: $desktop) { @content; }
}

@mixin desktop-only {
  @media all and (min-width: 1025px) { @content; }
}

@mixin all-but-mobile {
  @media all and (min-width: $mobile) { @content; }
}

@mixin all-but-tablet {
  @media all and (min-width: $tablet) { @content; }
}
