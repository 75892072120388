@import '../../../styles/helpers/responsive.scss';

.resort_wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;

  @include mobile {
    padding-top: 60px;
  }
}

.resort_section {
  position: relative;
  min-height: 819px;

  @include mobile {
    min-height: 545px;
  }
}

.content_block {
  position: relative;
  z-index: 2;
  max-width: 50%;
  width: 100%;

  @include mobile {
    max-width: 100%;
    pointer-events: none;
  }
}

.content_text {
  max-width: 522px;
  padding-right: 50px;

  @include mobile {
    max-width: initial;
    padding-right: 0;

    & > * {
      color: var(--color-white);
    }
  }
}

.slider_wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 80px 0 80px 80px;
  width: 50%;
  height: 100%;

  @include tablet {
    padding: 60px 0 60px 10px;
  }

  @include mobile {
    width: 100%;
    padding: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: var(--color-white);

    @include mobile {
      display: none;
    }
  }
}

.slider_max {
  max-width: 602px;
  margin-left: 38px;

  @include mobile {
    max-width: initial;
    margin-left: 0;
    height: 100%;
    padding-bottom: 60px;
  }
}

.slider {
  border-radius: 5px;

  @include laptop {
    border-radius: 5px 0 0 5px;
  }

  @include mobile {
    height: 100%;
    border-radius: 0;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0,0,0,0.5), transparent);

    @include mobile {
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.5) 100%);
    }
  }

  &::before {
    @include mobile {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(180deg, var(--color-bg) 0%, transparent 15%);
    }
  }
}

.numbers_wrapper {
  @include mobile {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 330px;
  }
}

.numbers_list {
  position: relative;
  padding: 22px 0 37px;
  background: linear-gradient(90.03deg, var(--color-white) 11.49%, rgba(255, 255, 255, 0.9) 55.36%, rgba(255, 255, 255, 0) 104.05%);

  @include mobile {
    background: none;
    padding: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    right: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: var(--color-white);

    @include mobile {
      display: none;
    }
  }
}

.number {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  max-width: calc(100% / 3);

  @include mobile {
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 5px 5px 10px;
    margin: 0 10px;
    max-height: 90px;
    box-shadow: 0px 4px 20px rgba(31, 51, 116, 0.2);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    height: 50px;
    width: 1px;
    background-color: var(--color-secondary);

    @include mobile {
      right: -10px;
      top: 50%;
      height: 40px;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  span {
    font-weight: 400;

    &:first-child {
      color: var(--color-secondary);
      font-size: var(--fs-48);
      font-weight: 700;
      margin-bottom: 5px;

      @include mobile {
        font-size: var(--fs-24);
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      text-align: center;
      padding: 0 10px;
      line-height: 1.1;

      @include mobile {
        font-size: var(--fs-14);
      }
    }
  }
}