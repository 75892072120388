@import '/styles/helpers/responsive.scss';

.group_wrapper {
  max-width: 100%;
  overflow: auto;
  padding-bottom: 30px;
}

.choice {
  position: relative;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-400);
    border-radius: 5px;
    font-weight: 500;
    font-size: var(--fs-14);
    cursor: pointer;
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
    white-space: nowrap;

    @include mobile {
      padding: 6px 8px;
    }
  }

  &.large {
    flex: 1 1 auto;
    margin-right: 20px;

    @include mobile {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    label {
      min-height: 40px;
      min-width: 160px;
      padding: 0 20px 2px;

      @include mobile {
        min-width: initial;
      }
    }
  }

  input:checked + label {
    background-color: var(--color-secondary-100);
    border-color: var(--color-secondary);
    color: var(--color-secondary);
  }
}
