@import '/styles/helpers/responsive.scss';

.calendar {
  width: 100%;
  margin: 20px 0 0;
}

button.has_offer {
  position: relative;
  background-color: var(--color-white);
  border-color: var(--color-grey-400);
  border-style: solid;
  border-width: 1px 0 1px 0;

  &:enabled:hover, &:enabled:focus {
    background-color: var(--color-white);
  }
  
  abbr {
    color: var(--color-black);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 7px;
    left: 0;
    width: 100%;
    height: 2px;
  }

  &.season_green {
    &::after {
      background-color: var(--color-success);
    }
  }

  &.season_orange {
    &::after {
      background-color: var(--color-alert);
    }
  }
  
  &.season_red {
    &::after {
      background-color: var(--color-secondary);
    }
  }

  &.start_slot {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid var(--color-grey-400);

    &::after {
      left: 5px;
      width: calc(100% - 5px);
    }
  }

  &.end_slot {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid var(--color-grey-400);

    &::after {
      width: calc(100% - 5px);
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 2px;
      right: 0;
      height: auto;
      background-color: transparent;
      font-size: 13px;
      font-weight: 100;
      letter-spacing: -1px;
      text-align: center;
      padding: 0 7px 0 5px;
      background-color: var(--color-white);
    }

    &.season_green {
      &::before {
        content: "€";
        color: var(--color-success);
      }
    }

    &.season_orange {
      &::before {
        content: "€€";
        color: var(--color-alert);
      }
    }

    &.season_red {
      &::before {
        content: "€€€";
        color: var(--color-secondary);
      }
    }
  }

  &.selected_week {
    background-color: var(--color-secondary-100);
    border-color: var(--color-secondary);

    &:hover, &:focus {
      background-color: var(--color-secondary-100);
    }

    &::before {
      background-color: var(--color-secondary-100);
    }
  }
}
